<template>
    <div class="html-default">
        <label class="form-label" style="font-size: 10px;">{{datetimeNow}}</label>
        <label class="form-label" style="float: right; font-size: 10px;">{{invoiceNumber}}</label>
        <br>
        <div class="row p-2">
            <div class="col-3 bg">
                <img class="item" :src="require('../../../public/img/avatars/Logo.jpg')" width="100%"/>
            </div>
            <div class="col-9 p-0">
                <br>
                <h2><b>PT. BOJONG WESTPLAS</b></h2>
                <label><b>Modern Pipe Manufacturer</b></label>
            </div>
        </div>

        <div style="text-align: center;">
            <h4><b>Faktur Penjualan</b></h4>
            <label>{{invoiceNumber}}</label>
        </div>

        <div class="row p-2">
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kantor</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyKantor }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Telepon</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPhoneNumber }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Fax</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyFax }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Pabrik</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPabrik }} <br> {{CompanyProvinsi}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Nomor NPWP</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{CompanyNPWP}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Tanggal</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{invoiceDate}}</label>
                    </div>
                </div>
                <div v-if="this.Type != 'Deposit'">
                    <div class="row">
                        <div class="col-sm-label">
                            <label class="form-label">Tanggal Jatuh Tempo</label>
                        </div>
                        <div class="col-sm-dot">
                            <label class="form-label">:</label>
                        </div>
                        <div class="col-sm-content">
                            <label class="form-label">{{invoiceDueDate}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kepada</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                    <label class="form-label"><span>{{customerName}} <br> {{customerAddress}} <br> {{customerCity}} - {{customerProvince}} <br> Indonesia</span></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2">
            <table border="1" class="mytable mytable-head">
                <thead>
                    <tr>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Jumlah</label></td>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Satuan</label></td>
                        <td class="px-3" width="40%" style="text-align: center;"><label>Nama Barang</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Harga</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Total</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td class="px-2"><label style="float: right;">{{item['base_quantity_string']}}</label></td>
                        <td class="px-2"><label>{{item['base_uom']}}</label></td>
                        <td class="px-2"><label>{{item['item_name']}}</label></td>
                        <td class="px-2"><label style="float: right;">Rp. {{item['price_discount_per_unit_string']}}</label></td>
                        <td class="px-2"><label style="float: right;">Rp. {{item['price_discount_string']}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-body">
                <tbody>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Jumlah harga jual</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalInvoice}}</label></td>
                    </tr>
                    <tr v-if="this.Type != 'Deposit'">
                        <td class="px-2" width="80%"><label style="float: right;">Uang Muka</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalDeposit}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Dasar Pengenaan Pajak</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalTaxBaseAmount}}</label></td>
                    </tr>
                    <tr v-if="this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">Dasar Pengenaan Pajak Nilai Lain</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{OtherTaxBaseAmount}}</label></td>
                    </tr>
                    <tr v-if="this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">PPN = 12% x DPPNL</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalInvoicePPN}}</label></td>
                    </tr>
                    <tr v-if="!this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">PPN = {{PPN}}% x Dasar Pengenaan Pajak</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalInvoicePPN}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Jumlah yang harus di bayar</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{NetInvoice}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-footer">
                <tbody>
                    <tr>
                        <td class="px-2" width="100%"><label>#Terbilang: {{InvoiceTotalString}} Rupiah</label></td>
                    </tr>
                </tbody>
            </table>
            <br>

            <div v-if="this.Type != 'Deposit'">
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Syarat Pembayaran</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{PaymentRule}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Nomor SJ</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{DONumber}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Nomor SP</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{SPNumber}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Nomor PO</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{PONumber}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="form-label">Nomor Faktur Pajak</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{NPWP}}</label>
                </div>
            </div>
            <br>
            
            <div class="row">
                <div class="col-8">
                    <label class="form-label"><span>Pembayaran ke PT BOJONG WESTPLAS<br>Dapat ditransfer melalui rekening<br>Bank BCA Cabang CBD Pluit - Jakarta<br>A/N: PT. BOJONG WESTPLAS<br>AC No: 806-040-8999</span></label>
                </div>
                <div class="col-4">
                    <label class="form-label"><span>Tangerang, {{invoiceDate}}<br>Hormat Kami,<br>PT Bojong Westplas<br><br><br><br><br><br><br><br><br><br>{{Name}}</span></label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import invoiceServices from '../../Areas/Transaction/Invoice/Script/InvoiceServices';
import angkaTerbilang from '@develoka/angka-terbilang-js';

export default {
    name: 'InvoiceHtml',
    data(){
        return {
            FeatureVersion : '',
            Id: this.$route.params.id,
            Type: this.$route.params.type,
            datetimeNow: '',
            invoiceNumber: '',
            customerName: '',
            customerAddress: '',
            customerCity: '',
            customerProvince: '',
            invoiceDate: '',
            invoiceDueDate: '',
            items: [],
            DONumber: [],
            PONumber: '',
            SPNumber: '',
            NPWP: '',
            PaymentRule: '',
            Name: this.$cookies.get('Name'),
            TotalInvoice: '',
            TotalInvoicePPN: '',
            NetInvoice: '',
            InvoiceTotalString: '',
            PPN: '',
            TotalDeposit: '',
            TotalDepositAmount: 0,
            TotalTaxBaseAmount: '',
            OtherTaxBaseAmount: '',

            //Company Detail
            CompanyPhoneNumber : '',
            CompanyFax : '',
            CompanyKantor : '',
            CompanyNPWP : '',
            CompanyPabrik : '',
            CompanyProvinsi : '',
        }
    },
    async mounted () {
        var featureData = await this.$globalfunc.getFeatureSetting(2);
        var feature = featureData[0].feature_value.version;
        this.FeatureVersion = feature;

        //Company Detail
        var companyData = await this.$globalfunc.getCompanyInfo();
        this.CompanyPhoneNumber = companyData.Telepon;
        this.CompanyFax = companyData.Fax;
        this.CompanyKantor = companyData.Kantor;
        this.CompanyNPWP = companyData.NPWP;
        this.CompanyPabrik = companyData.Pabrik;
        this.CompanyProvinsi = companyData.Provinsi;

        var data = await invoiceServices.getDetailInvoice(this.Id);
        document.title = data.invoice_number;
        var customerData = data.customer;
        
        this.datetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        this.invoiceNumber = data.invoice_number;
        this.customerName = customerData.contact_name;
        this.customerAddress = customerData.metadata.profile.address;
        this.customerCity = customerData.metadata.profile.city;
        this.customerProvince = customerData.metadata.profile.province;

        this.invoiceDate = moment(data.invoice_date).format("DD MMMM YYYY");
        
        //table
        var invoiceData = invoiceServices.itemInvoicePDF(data.transaction_invoice_detail);
        this.items = invoiceData.grid_data;

        if(this.Type != 'Deposit'){
            var duedateDays = data.purchase_order.term_of_payment;
            this.invoiceDueDate = moment(data.due_date).format("DD MMMM YYYY");
        
            //GetDeliveryOrderData
            var deliveryOrderData = invoiceServices.deliveryOrderArrayToString(data.transaction_invoice_delivery_order);
            var deliveryOrderNumber = JSON.stringify(deliveryOrderData.delivery_order_number).replace(/"/g, '').replace(/,/g, ', ').replace('[', '').replace(']', '');
            
            this.DONumber = deliveryOrderNumber;
            this.SPNumber = data.sp_number;
            this.PONumber = data.purchase_order_number;
            this.TotalDepositAmount = data.total_deposit;
            this.TotalDeposit = kendo.toString(data.total_deposit, "n0");

            if(data.purchase_order.payment_method == 'Cash'){
                this.PaymentRule = 'Cash (Tunai)';
            }else if(data.purchase_order.payment_method == 'Credit'){
                this.PaymentRule = 'Credit (Kredit)' + ' ' + duedateDays + ' hari dari tanggal invoice.';
            }
        }

        if (data.tax_invoice != null || data.tax_invoice != undefined) {
            var taxInvoice1 = data.tax_invoice.substring(0,3);
            var taxInvoice2 = data.tax_invoice.substring(3,6);
            var taxInvoice3 = data.tax_invoice.substring(6,8);
            var taxInvoice4 = data.tax_invoice.substring(8,100);
            
            var newTaxInvoice = taxInvoice1 + '.' + taxInvoice2 + '.' + taxInvoice3 + '.' + taxInvoice4;
            this.NPWP = newTaxInvoice;
        }
        else {
            this.NPWP = data.tax_invoice
        }
        this.PPN = data.tax_percentage;

        var totalTaxBaseAmount = invoiceData.total_price_discount - this.TotalDepositAmount; //DPP
        var otherTaxBaseAmount = totalTaxBaseAmount * (this.PPN/12); //DPPNL
        var invoicePpn = Math.round(otherTaxBaseAmount * (12/100));
        
        this.TotalInvoice = kendo.toString(invoiceData.total_price_discount, "n0");
        this.TotalTaxBaseAmount = kendo.toString(totalTaxBaseAmount, "n0");
        this.OtherTaxBaseAmount = kendo.toString(otherTaxBaseAmount, "n0");
        this.TotalInvoicePPN = kendo.toString(this.FeatureVersion ? invoicePpn : invoiceData.total_tax_amount, "n0");
        this.NetInvoice = kendo.toString(invoiceData.total_vat, "n0");
        this.InvoiceTotalString = this.$globalfunc.titleCase(angkaTerbilang(invoiceData.total_vat));
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
.bg {
    background-color: transparent;
}
.item {
    mix-blend-mode: multiply;
}
</style>