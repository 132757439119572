<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
            <h2>{{FormType}} Quality Control : {{ProductionMutationCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionCode" v-model="ProductionCode" class="font-weight-bold" disabled/>
                            <label id="errorProductionCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Quality Control</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionMutationCode" v-model="ProductionMutationCode" class="font-weight-bold" disabled/>
                            <label id="errorProductionMutationCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Approve</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="ProductionMutationDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                                v-bind:disabled="this.DisabledInput"
                            ></date-picker>
                            <CInput id="ProductionMutationDate2" v-model="ProductionMutationDate2" class="font-weight-bold" hidden/>
                            <label id="errorProductionMutationDate2" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Machine" v-model="Machine" class="font-weight-bold" disabled/>
                            <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Storage" v-model="Storage" class="font-weight-bold" disabled/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="Note" class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Quality Control Item</h4></label>
                            <button class="btn btn-primary float-right" @click="AddWasteRejectedItem()" :hidden="this.HiddenAction" >
                                <i class="fa fa-plus"></i> Add Waste/Rejected Item
                            </button>
                            <hr>
                            
                            <datasource ref="ProductionMutationItemDataSource" 
                                :data="this.ProductionMutationItemGridData" 
                                :page-size="10" 
                                :schema-model-fields="this.ProductionMutationItemGridDataSchemaModel"
                            />

                            <kendo-grid ref="gridProductionMutationItem"
                                :data-source-ref="'ProductionMutationItemDataSource'"
                                :editable="this.Editable"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :cellClose="cellClose"
                            >                                                    
                                <kendo-grid-column  :title="'Action'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :template="this.CustomButton"></kendo-grid-column>

                                <kendo-grid-column  :field="'production_mutation_id'"
                                                    :title="'Id Mutasi Produksi'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'production_mutation_item_id'"
                                                    :title="'Id Item Mutasi Produksi'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'item_id'"
                                                    :title="'Id Item'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'item_type'"
                                                    :title="'Tipe Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'storage_id'"
                                                    :title="'Gudang'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Barang'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Barang'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'requested_quantity'"
                                                    :title="'Requested Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'total_approved_quantity'"
                                                    :title="'Approved Quantity'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'secondary_uom'"
                                                    :title="'Satuan Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_conv_amount'"
                                                    :title="'Nilai Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_requested_quantity'"
                                                    :title="'Requested Quantity Konversi'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'secondary_total_approved_quantity'"
                                                    :title="'Approved Quantity Konversi'"
                                                    :width="300"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                            </kendo-grid>
                        </div>

                        <request-material-approver-form ref="RequestMaterialItemDetailModal" />
                        <quality-control-item-form ref="QualityControlItemModal" :saveQualityControlItemGrid="saveQualityControlItemGrid" />
                    </CRow>
                    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/quality-control')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

import requestMaterialService from '../../RequestMaterial/Script/RequestMaterialServices.js';
import requestMaterialApproverForm from '../../RequestMaterial/Component/RequestMaterialApproverForm.vue';
import qualityControlItemForm from '../Component/QualityControlItemForm.vue';

export default {
    name: 'QualityControlForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'request-material-approver-form' : requestMaterialApproverForm,
        'quality-control-item-form' : qualityControlItemForm,
    },
    data() {
        return {
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            ModalTitle: '',

            ProductionId: '',
            ProductionCode: '',
            ProductionMutationId: '',
            ProductionMutationCode: '',
            ProductionScheduleId: '',
            ProductionScheduleCode: '',
            ProductionMutationType: '',
            ProductionMutationDate: '',
            ProductionMutationDate2: '',
            Machine: '',
            Storage: '',
            Note: '',
            
            Status:'',
            StatusData:[],

            ProductionMutationItemGridData: [],
            ProductionMutationItemGridDataSchemaModel: {
                production_mutation_id: { type: "string", editable: false},
                production_mutation_item_id: { type: "string", editable: false},
                item_id: { type: "string", editable: false},
                item_type: { type: "string", editable: false},
                storage_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                base_uom: { type: "string", editable: false},
                requested_quantity: { type: "number", editable: false},
                total_approved_quantity: { type: "number", editable: true, validation: { required:true, min:0 }},
                secondary_uom: { type: "string", editable: false},
                secondary_conv_amount: { type: "number", editable: false},
                secondary_requested_quantity: { type: "number", editable: false},
                secondary_total_approved_quantity: { type: "number", editable: true, validation: { required:true, min:0 }},
            },
            
            SaveType: '',
            CustomButton: `
                <div class="btn-group">
                    <button type="button" class="btn btn-info btn-sm rounded" id="ViewQuantityButton">
                        <i class="fa fa-eye"></i> </span>
                    </button>
                </div>
            `,
            HiddenAction: false,
            DisabledInput: false,
            ItemTypeEditable: false,
            Editable: true,
        }
    },
    async mounted() {
        var vueComponent = this;
        var grid = this.$refs.gridProductionMutationItem.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#ViewQuantityButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            vueComponent.$refs.RequestMaterialItemDetailModal.viewClick(dataItem.production_mutation_item_id)
        })

        this.$loading(true);
        $(".form-error").css("display", "none");

        this.FormType = 'Edit';
        
        var data = await requestMaterialService.getRequestMaterialByIdQuery('quality_control', this.Id);
        
        this.ProductionId = data.production_id;
        this.ProductionCode = data.production_code;
        this.ProductionMutationId = data.production_mutation_id;
        this.ProductionMutationCode = data.production_mutation_code;
        this.ProductionScheduleId = data.production_schedule_id;
        this.ProductionScheduleCode = data.production_schedule_code;
        
        this.ProductionMutationType = data.production_mutation_type;

        if (data.production_mutation_date == null) {
            this.ProductionMutationDate = null;
        }
        else {
            this.ProductionMutationDate = moment(data.production_mutation_date).format("YYYY-MM-DD");
        }
        this.Machine = data.machine_code;
        this.Storage = data.storage_id;
        this.Note = data.notes;

        this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_quality_control');
        this.Status = this.StatusData.find(c => c.value == data.status);
        
        if (data.status != 'New') {
            this.Editable = false;
            this.HiddenAction = true;
        }
        else {
            this.Editable = true;
            this.HiddenAction = false;
        }
        
        this.ProductionMutationItemGridData = [];
        if (data.production_mutation_item != null) {
            this.ProductionMutationItemGridData = requestMaterialService.detailItemDataObject(data.production_mutation_item)
        }

        this.SaveType = 'Edit';
        
        if(this.View == 'true'){
            this.FormType = 'View';
            $(".save-button").hide();
        }
        else {
            $(".save-button").show();
        }
        
        this.$loading(false);
    },
    methods: {
        AddWasteRejectedItem() {
            this.$refs.QualityControlItemModal.addClick(this.ProductionMutationId);
        },
        saveQualityControlItemGrid(data) {
            var gridData = this.$refs.gridProductionMutationItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridData, 'RequestMaterial-MutationItemGrid');
            var newData = requestMaterialService.gridDataConverter(data, this.ProductionMutationId);

            itemData = itemData.concat(newData);
            this.ProductionMutationItemGridData = itemData;
        },
        cellClose(e) {
            var gridData = this.$refs.gridProductionMutationItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridData, 'RequestMaterial-MutationItemGrid');

            var dataItem = e.sender.dataItem($(e.container).parent());            
            var changesItem = gridData.filter(obj => { return obj.item_id == dataItem.item_id });

            if (changesItem[0].dirtyFields.hasOwnProperty('total_approved_quantity')) {
                changesItem[0].secondary_total_approved_quantity = (changesItem[0].total_approved_quantity / changesItem[0].secondary_conv_amount);
                changesItem[0].dirtyFields = {};
            }
            
            if (changesItem[0].dirtyFields.hasOwnProperty('secondary_total_approved_quantity')) {
                changesItem[0].total_approved_quantity = (changesItem[0].secondary_total_approved_quantity * changesItem[0].secondary_conv_amount);
                changesItem[0].dirtyFields = {};
            }
            
            var changeItemData = this.$globalfunc.objectToArrayConverter(changesItem, 'RequestMaterial-MutationItemGrid');

            //replace same item id after the changes
            itemData = itemData.map(obj => changeItemData.find(o => o.item_id === obj.item_id) || obj);
            this.ProductionMutationItemGridData = itemData;
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
                        
            if (this.Status == null || this.Status.value === undefined) {
                this.errorShow('errorStatus');
            }

            if (this.Status.value == 'Approved') {
                if (this.ProductionMutationDate == null || this.ProductionMutationDate == 'Invalid date') {
                    this.errorShow('errorProductionMutationDate2');
                }

                // var ItemGridData = this.$refs.gridProductionMutationItem.kendoWidget().dataSource._data;

                // for(var i=0; i<ItemGridData.length; i++){
                //     if (ItemGridData[i].total_approved_quantity < ItemGridData[i].requested_quantity) {
                //         this.$swal("Error", "Total Approve Quantity pada Item " + ItemGridData[i].product_code 
                //             + " tidak mencukupi", "error");
                //         this.Error++;
                //         break;
                //     }
                // }
            }
        },
        async saveClick() {
            this.inputValidation();

            if (this.Error == 0) {
                var ItemGridData = this.$refs.gridProductionMutationItem.kendoWidget().dataSource._data;

                var ProductionMutationItemArray = this.$globalfunc.objectToArrayConverter(ItemGridData, "RequestMaterial-SaveMutationItemGrid")

                const productionMutationData = {
                    production_mutation_type : this.ProductionMutationType,
                    production_mutation_date : this.ProductionMutationDate,
                    status : this.Status.value,
                    production_id : this.ProductionId,
                    production_schedule_id : this.ProductionScheduleId,
                    notes : this.Note,
                    items : ProductionMutationItemArray,
                }

                this.$loading(true);
                
                const variables = {
                    id: parseInt(this.ProductionMutationId),
                    data : productionMutationData
                }
                
                requestMaterialService.editQuery(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", response.successUpdate, "success").then(
                        this.$router.push({ path: '/quality-control' })
                    );
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>