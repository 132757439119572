import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';
import { invoice } from '../../../../infrastructure/constant/variable.js';

class InvoiceService {
    readInvoiceQuery(){
        var query = `query($paging:ServerPaging, $status:[String]){
                        GetTransactionInvoice(Paging:$paging, Status:$status){
                            transaction_invoice{
                                invoice_id
                                invoice_type
                                status
                                invoice_date
                                invoice_number
                                customer_name
                                due_date
                                sp_number
                                total
                                tax_invoice
                                notes
                                project_type
                                created_at
                            }
                            total
                        }
                    }`;
        return query;
    }
    
    readDeliveryOrderQuery(){
        var query = `query {
            GetInvoiceableDeliveryOrder {
                created_at
                last_update
                delivery_order_id
                delivery_order_number
                storage_id
                purchase_order_id
                purchase_order_number
                sp_number
                project_id
                project_name
                project_type
                project_detail
                customer_name
                customer_metadata
                notes
                additional_notes
                term_of_payment
            }
        }`;
        return query;
    }

    readInvoiceDetailQuery(){
        var query = gql`query($id:Int, $check:Checker, $status:[String], $paging:ServerPaging){
            GetTransactionInvoice(InvoiceID: $id, Check:$check, Status:$status, Paging:$paging){
                transaction_invoice {
                    created_at
                    last_update
                    invoice_id
                    invoice_type
                    invoice_number
                    invoice_date
                    due_date
                    status
                    purchase_order_id
                    purchase_order_number
                    purchase_order {
                        created_at
                        last_update
                        purchase_order_id
                        purchase_order_number
                        sp_number
                        purchase_order_date
                        status
                        project_id
                        project_name
                        project_type
                        payment_method
                        sales_id
                        sales_name
                        customer_id
                        customer_name
                        storage_id
                        price_category_id
                        purchase_order_sequence
                        purchase_order_type
                        notes
                        printed_notes
                        address
                        attachment
                        down_payment
                        sample
                        total
                        tax_percentage
                        on_going_purchase_order_total_by_project_id
                        on_going_purchase_order_total_by_customer_id
                        invoice_number
                        delivery_order_number
                        created_by
                        approved_by
                        payment_status
                        term_of_payment
                        booking_purchase_order_id
                    }
                    sp_number
                    customer_id
                    customer_name
                    customer {
                        created_at
                        last_update
                        contact_id
                        contact_code
                        contact_type
                        contact_name
                        status
                        metadata
                        attachment1
                        attachment2
                        contact_debit
                        on_going_purchase_order_total_by_customer_id
                    }
                    project_type
                    tax_invoice
                    tax_percentage
                    tax_invoice_deposit
                    notes
                    total
                    total_deposit
                    total_rd
                    created_by
                    transaction_invoice_delivery_order {
                        created_at
                        last_update
                        invoice_id
                        delivery_order_id
                        delivery_order_number
                    }
                    transaction_invoice_detail {
                        created_at
                        last_update
                        invoice_id
                        item_id
                        item_name
                        product_code
                        barcode
                        type_detail_id
                        uom
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                        price_discount_per_unit
                        price_discount
                        fulfilled_quantity
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount_plus
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        discount_plus_amount
                        tax_amount
                        deposit_amount
                        base_uom
                        conv_amount
                        vat_rd
                        tax_amount_rd
                        vat_per_unit_rd
                    }
                }
                total
            }
        }`;
        return query;
    }

    async getDetailInvoice(id){
        const variables = {
            id : id
        }

        var query = this.readInvoiceDetailQuery();
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionInvoice.transaction_invoice[0];
    }

    async getDetailInvoiceExcel(selectedId, checkAll, paging, status){
        var IsAll = checkAll == 'true' ? true : false;
        var Params = selectedId == '' ? [] : selectedId.split(',');
        var variables = null;

        if(IsAll){
            variables = {
                check : {
                    IsAll : IsAll,
                    Params : Params
                },
                status : status,
                paging : paging
            }
        }
        else{
            variables = {
                check : {
                    IsAll : IsAll,
                    Params : Params,
                }
            }
        }
        
        var query = this.readInvoiceDetailQuery();
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetTransactionInvoice.transaction_invoice;
    }

    async getInvoiceableItemList(id){
        var array = JSON.parse(id);
        
        const variables = {
            id : array
        }
        var query = gql`query($id:[Int]){
            GetTransactionInvoiceableItemListV2(DeliveryOrderIds: $id) {
                delivery_order_id
                delivery_order_number
                item_id
                item_name
                product_code
                barcode
                type_detail_id
                uom
                base_uom
                conv_amount
                quantity
                price_per_unit
                price
                price_discount_per_unit
                price_discount
                vat_per_unit
                vat
                discount1
                discount2
                discount3
                discount4
                discount5
                discount_cash
                discount1_amount
                discount2_amount
                discount3_amount
                discount4_amount
                discount5_amount
                discount_cash_amount
                tax_amount
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetTransactionInvoiceableItemListV2;
    }

    async getPurchaseOrderQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetTransactionPurchaseOrder(PurchaseOrderID:$id){
                transaction_purchase_order {
                    created_at
                    last_update
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    purchase_order_date
                    status
                    project_id
                    project {
                        created_at
                        last_update
                        project_id
                        project_code
                        project_type
                        project_date
                        project_duration
                        project_name
                        status
                        pre_project_id
                        pre_project_code
                        pre_project_name
                        forecast_type
                        storage_id
                        storage_name
                        price_category_id
                        price_category_name
                        areacode
                        areaname
                        sales_id
                        sales_name
                        city
                        attachment
                        notes
                        project_detail
                    }
                    project_name
                    project_type
                    payment_method
                    sales_id
                    sales {
                        created_at
                        last_update
                        contact_id
                        contact_code
                        contact_type
                        contact_name
                        status
                        metadata
                        attachment1
                        attachment2
                    }
                    sales_name
                    customer_id
                    customer {
                        created_at
                        last_update
                        contact_id
                        contact_code
                        contact_type
                        contact_name
                        status
                        metadata
                        attachment1
                        attachment2
                        contact_debit
                    }
                    customer_name
                    storage_id
                    price_category_id
                    purchase_order_sequence
                    purchase_order_type
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    sample
                    total
                    tax_percentage
                    invoice_number
                    delivery_order_number
                    created_by
                    approved_by
                    payment_status
                    purchase_order_detail {
                        created_at
                        last_update
                        purchase_order_id
                        item_id
                        product_code
                        barcode
                        type_detail_id
                        item_name
                        uom
                        base_uom
                        conv_amount
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                        quantity
                        quantity_base
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        tax_amount
                    }
                    booking_order {
                        created_at
                        last_update
                        booking_order_id
                        purchase_order_id
                        item_id
                        product_code
                        barcode
                        type_detail_id
                        item_name
                        uom
                        base_uom
                        conv_amount
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                        quantity
                        quantity_base
                        quantity_production
                        quantity_ready
                        quantity_current
                        quantity_purchase_order
                        stock_sales
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        tax_amount
                        customer_id
                        customer_name
                        purchase_order_number
                        storage_id
                    }
                    term_of_payment
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionPurchaseOrder.transaction_purchase_order[0];
    }
    
    invoiceDepositItemDataChange(data, discountPlus, PPNTax){
        if(data == null){
            data = [
                {
                    item_id : '-1',
                    product_code : 'Deposit',
                    item_name: 'Deposit',
                    uom: 'Pcs',
                    base_uom: 'Deposit',
                    price_per_unit: 0,
                    price: 0,
                    vat_per_unit: 0,
                    vat: 0,
                    price_discount_per_unit: 0,
                    price_discount: 0,
                    quantity: 1,
                    discount1: 0,
                    discount2: 0,
                    discount3: 0,
                    discount4: 0,
                    discount5: 0,
                    discount_cash: 0,
                    discount_plus: 0,
                    discount1_amount: 0,
                    discount2_amount: 0,
                    discount3_amount: 0,
                    discount4_amount: 0,
                    discount5_amount: 0,
                    discount_cash_amount: 0,
                    discount_plus_amount: 0,
                    tax_amount: 0,
                    deposit_amount: 0,
                    conv_amount : 1,
                    //RoundDown
                    tax_amount_rd: 0,
                    vat_per_unit_rd: 0,
                    vat_rd: 0,
                }
            ];
        };

        var arrayData = [];
        if(data != null){
            var inputPriceDiscountPerUnit = data[0].price_per_unit;

            var discountPlusAmount = inputPriceDiscountPerUnit * (discountPlus / 100);
            var priceDiscountPerUnit = inputPriceDiscountPerUnit - discountPlusAmount;
            var taxAmount = priceDiscountPerUnit * (PPNTax / 100);
            var vatPerUnit = priceDiscountPerUnit + taxAmount;
            
            //RoundDown
            var discountPlusAmountRD = Math.floor(inputPriceDiscountPerUnit * (discountPlus / 100));
            var priceDiscountPerUnitRD = inputPriceDiscountPerUnit - discountPlusAmountRD;
            var taxAmountRD = priceDiscountPerUnitRD * (PPNTax / 100);
            var vatPerUnitRD = priceDiscountPerUnit + taxAmountRD;
            
            var str = { 
                item_id: data[0].item_id,
                product_code : data[0].product_code,
                item_name: data[0].item_name,
                uom: data[0].uom,
                base_uom: data[0].base_uom,
                price_per_unit: inputPriceDiscountPerUnit,
                price: inputPriceDiscountPerUnit * data[0].quantity,
                vat_per_unit: vatPerUnit,
                vat: vatPerUnit * data[0].quantity,
                price_discount_per_unit: priceDiscountPerUnit,
                price_discount: priceDiscountPerUnit * data[0].quantity,
                quantity: data[0].quantity,
                conv_amount: data[0].conv_amount,
                discount1: data[0].discount1,
                discount2: data[0].discount2,
                discount3: data[0].discount3,
                discount4: data[0].discount4,
                discount5: data[0].discount5,
                discount_cash: data[0].discount_cash,
                discount_plus: discountPlus,
                discount1_amount: data[0].discount1_amount,
                discount2_amount: data[0].discount2_amount,
                discount3_amount: data[0].discount3_amount,
                discount4_amount: data[0].discount4_amount,
                discount5_amount: data[0].discount5_amount,
                discount_cash_amount: data[0].discount_cash_amount,
                discount_plus_amount: discountPlusAmount,
                tax_amount: taxAmount,
                deposit_amount: 0,
                //RoundDown
                tax_amount_rd: taxAmountRD,
                vat_per_unit_rd: vatPerUnitRD,
                vat_rd: vatPerUnitRD * data[0].quantity,
            }
            arrayData.push(str);
        }
        
        return arrayData;
    }

    invoiceItemDataChange(data, discountPlus, PPNTax, formType){
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var discountPlusAmount = 0;
                var priceDiscountPerUnit = 0;
                var taxAmount = 0;
                var vatPerUnit = 0;
                var vat = 0;
                var depositAmount = 0;

                //RoundDown
                var discountPlusAmountRD = 0;
                var priceDiscountPerUnitRD = 0;
                var taxAmountRD = 0;
                var vatPerUnitRD = 0;
                var vatRD = 0;

                if(formType == "Add"){
                    discountPlusAmount = (data[i].price_discount_per_unit / data[i].conv_amount) * (discountPlus / 100) * data[i].conv_amount;
                    priceDiscountPerUnit = data[i].price_discount_per_unit - discountPlusAmount;
                    taxAmount = priceDiscountPerUnit * (PPNTax / 100);
                    vatPerUnit = priceDiscountPerUnit + taxAmount;
                    vat = vatPerUnit * data[i].quantity;

                    //RoundDown
                    discountPlusAmountRD = Math.floor((data[i].price_discount_per_unit / data[i].conv_amount) * (discountPlus / 100)) * data[i].conv_amount;
                    priceDiscountPerUnitRD = data[i].price_discount_per_unit - discountPlusAmountRD;
                    taxAmountRD = priceDiscountPerUnitRD * (PPNTax / 100);
                    vatPerUnitRD = priceDiscountPerUnitRD + taxAmountRD;
                    vatRD = vatPerUnitRD * data[i].quantity;
                }else{
                    discountPlusAmount = data[i].discount_plus_amount;
                    priceDiscountPerUnit = data[i].price_discount_per_unit;
                    taxAmount = data[i].tax_amount;
                    vatPerUnit = data[i].vat_per_unit;
                    vat = data[i].vat;
                    depositAmount = data[i].deposit_amount;

                    //RoundDown
                    taxAmountRD = data[i].tax_amount_rd;
                    vatPerUnitRD = data[i].vat_per_unit_rd;
                    vatRD = data[i].vat_rd;
                }

                var str = { 
                    item_id: data[i].item_id,
                    product_code : data[i].product_code,
                    item_name: data[i].item_name,
                    uom: data[i].uom,
                    base_uom: data[i].base_uom,
                    price_per_unit: data[i].price_per_unit,
                    price: data[i].price,
                    vat_per_unit: vatPerUnit,
                    vat: vat,
                    price_discount_per_unit: priceDiscountPerUnit,
                    price_discount: priceDiscountPerUnit * data[i].quantity,
                    quantity: data[i].quantity,
                    conv_amount: data[i].conv_amount,
                    discount1: data[i].discount1,
                    discount2: data[i].discount2,
                    discount3: data[i].discount3,
                    discount4: data[i].discount4,
                    discount5: data[i].discount5,
                    discount_cash: data[i].discount_cash,
                    discount_plus: discountPlus,
                    discount1_amount: data[i].discount1_amount,
                    discount2_amount: data[i].discount2_amount,
                    discount3_amount: data[i].discount3_amount,
                    discount4_amount: data[i].discount4_amount,
                    discount5_amount: data[i].discount5_amount,
                    discount_cash_amount: data[i].discount_cash_amount,
                    discount_plus_amount: discountPlusAmount,
                    tax_amount: taxAmount,
                    deposit_amount: depositAmount,
                    //RoundDown
                    tax_amount_rd: taxAmountRD,
                    vat_per_unit_rd: vatPerUnitRD,
                    vat_rd: vatRD,
                }
                arrayData.push(str);
            }
        }

        return arrayData;
    }
    
    getEditItemInvoice(data){
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { base_uom: null,
                            base_weight: data[i].base_weight,
                            conv_amount: null,
                            disc_1: data[i].disc1,
                            disc_2: data[i].disc2,
                            disc_3: data[i].disc3,
                            disc_4: data[i].disc4,
                            disc_5: data[i].disc5,
                            disccash: data[i].disccash,
                            item_id: data[i].item_id,
                            product_code : data[i].product_code,
                            item_name: data[i].item_name,
                            price: data[i].price,
                            qty: data[i].flmt_qty,
                            uom: data[i].uom,
                            uom_conv: null,
                            total: data[i].priceppn,
                            price_per_unit: data[i].price_per_unit,
                            priceppn_per_unit: data[i].priceppn_per_unit
                        }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    invoiceDetailTemplate(data){
        var type = data.invoice_type == 1 ? 'Invoice' : 'Deposit';
        var invoice_date = moment(data.invoice_date).format('DD MMMM YYYY'); 
        var customerData = data.customer;

        if(type == 'Invoice'){
            var deliveryOrderData = this.deliveryOrderArrayToString(data.transaction_invoice_delivery_order);
            var deliveryOrderNumberList = JSON.stringify(deliveryOrderData.delivery_order_number).replace(/"/g, '').replace(/,/g, ', ').replace('[', '').replace(']', '');

            var dueDate = moment(data.due_date).format('DD MMMM YYYY');
        }
        
        var html = `
                <div class="row p-1" width="100%">
                    <div class="col-5 px-2">
                        <label class="form-label"><b>Invoice Info</b></label>
                        <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">Tipe Invoice</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">Invoice</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">No. Invoice</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.invoice_number +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">No. Faktur Pajak</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.tax_invoice +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">Tgl. Invoice</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ invoice_date +`</label>
                                </div>
                            </div>`;
        if(type == 'Invoice'){
            html = html +   `<div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">Tgl. Jatuh Tempo</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ dueDate +`</label>
                                </div>
                            </div>`;}

        html = html +       `<div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">Status</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.status +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">Note</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.notes +`</label>
                                </div>
                            </div>`
        if(type == 'Invoice'){
            html = html +   `<div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">No. PO</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.purchase_order_number +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-3">
                                    <label class="form-label">No. SP</label>
                                    <label class="f
                                    orm-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">`+ data.sp_number +`</label>
                                </div>
                            </div>`;}
        html = html +   `</div>
                    </div>
                    <div class="col-4 px-2">
                        <label class="form-label"><b>Customer Info</b></label>
                        <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">Nama</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ customerData.contact_name +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">No. Telepon</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ customerData.metadata.profile.phone1 +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">No. HandPhone</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8"
                                    <label class="form-label">`+ customerData.metadata.profile.handphone +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">Fax</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ customerData.metadata.profile.fax +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">Email</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ customerData.metadata.profile.email +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">Alamat</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ customerData.metadata.profile.address +`</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 px-2">`
        if(type == 'Invoice'){
            html = html + `
                        <label class="form-label"><b>Delivery Order Info</b></label>
                        <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">No. DO</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ deliveryOrderNumberList +`</label>
                                </div>
                            </div>
                            <div class="row" width="100%">
                                <div class="col-4">
                                    <label class="form-label">Gudang</label>
                                    <label class="form-label" style="float:right">:&nbsp;</label>
                                </div>
                                <div class="col-8">
                                    <label class="form-label">`+ data.purchase_order.storage_id +`</label>
                                </div>
                            </div>
                        </div>`;}
        html = html +  `
                    </div>
                </div>
                <hr>
            `;
        return html;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewTransactionInvoice!){
            CreateTransactionInvoice(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewTransactionInvoice!){
            UpdateTransactionInvoice(InvoiceId:$id, New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    invoiceExcelHeaderSizeSingle(){
        var size = [
            {width: 3}, //colA
            {width: 20}, //colB
            {width: 50}, //colC
            {width: 80}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 50}, //colI
            {width: 50}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 30}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS
        ];

        return size;
    }

    async invoiceExcelSingle(data){
        var arrayObject = [];

        //Row 1
        var row1 = {colA : 'FK',
                    colB : 'KD_JENIS_TRANSAKSI',
                    colC : 'FG_PENGGANTI',
                    colD : 'NOMOR_FAKTUR',
                    colE : 'MASA_PAJAK',
                    colF : 'TAHUN_PAJAK',
                    colG : 'TANGGAL_FAKTUR',
                    colH : 'NPWP',
                    colI : 'NAMA',
                    colJ : 'ALAMAT_LENGKAP',
                    colK : 'JUMLAH_DPP',
                    colL : 'JUMLAH_PPN',
                    colM : 'JUMLAH_PPNBM',
                    colN : 'ID_KETERANGAN_TAMBAHAN',
                    colO : 'FG_UANG_MUKA',
                    colP : 'UANG_MUKA_DPP',
                    colQ : 'UANG_MUKA_PPN',
                    colR : 'UANG_MUKA_PPNBM',
                    colS : 'REFERENSI'};
        arrayObject.push(row1);

        //Row 2 
        var row2 = {colA : 'LT',
                    colB : 'NPWP',
                    colC : 'NAMA',
                    colD : 'JALAN',
                    colE : 'BLOK',
                    colF : 'NOMOR',
                    colG : 'RT',
                    colH : 'RW',
                    colI : 'KECAMATAN',
                    colJ : 'KELURAHAN',
                    colK : 'KABUPATEN',
                    colL : 'PROPINSI',
                    colM : '',
                    colN : '',
                    colO : '',
                    colP : '',
                    colQ : '',
                    colR : '',
                    colS : ''};
        arrayObject.push(row2);

        //Row 3
        var row3 = {colA : 'OF',
                    colB : 'KODE_OBJEK',
                    colC : 'NAMA',
                    colD : 'HARGA_SATUAN',
                    colE : 'JUMLAH_BARANG',
                    colF : 'HARGA_TOTAL',
                    colG : 'DISKON',
                    colH : 'DPP',
                    colI : 'PPN',
                    colJ : 'TARIF_PPNBM',
                    colK : 'PPNBM',
                    colL : '',
                    colM : '',
                    colN : '',
                    colO : '',
                    colP : '',
                    colQ : '',
                    colR : '',
                    colS : ''};
        arrayObject.push(row3);

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var detail = this.itemInvoiceExcel(data[i].transaction_invoice_detail);
                var taxinvoice = data[i].tax_invoice == null ? "" : data[i].tax_invoice.replace(/\./g, '');
                var rowFK = {   colA : 'FK',
                                colB : taxinvoice.substring(0,2),
                                colC : taxinvoice.substring(2,3),
                                colD : taxinvoice.substring(3),
                                colE : moment(data[i].invoice_date).format("MM"),
                                colF : moment(data[i].invoice_date).format("YYYY"),
                                colG : moment(data[i].invoice_date).format("DD/MM/YYYY"),
                                colH : data[i].customer.metadata.profile.npwp == null ? "" : data[i].customer.metadata.profile.npwp.replace(/\./g, '').replace(/-/g, ''),
                                colI : data[i].customer.contact_name,
                                colJ : data[i].customer.metadata.profile.address,
                                colK : Math.round(detail.total_price_discount),
                                colL : detail.total_tax_amount,
                                colM : '0',
                                colN : '',
                                colO : '0',
                                colP : '0',
                                colQ : '0',
                                colR : '0',
                                colS : data[i].invoice_number}
                arrayObject.push(rowFK);

                var companyData = await globalfunc.getCompanyInfo();
                var CompanyNPWP = companyData.NPWP.replace(/\./g,'').replace('-','');
                var CompanyPabrik = companyData.Pabrik.toUpperCase();
                var CompanyProvinsi = companyData.Provinsi.toUpperCase();

                var rowLT = {   colA : 'LT',
                                colB : CompanyNPWP,
                                colC : 'PT. BOJONG WESTPLAS',
                                colD : CompanyPabrik + ', KAB. ' + CompanyProvinsi,
                                colE : '-',
                                colF : '-',
                                colG : '0',
                                colH : '0',
                                colI : '-',
                                colJ : '-',
                                colK : '-',
                                colL : '-',
                                colM : '-',
                                colN : '-',
                                colO : '',
                                colP : '',
                                colQ : '',
                                colR : '',
                                colS : ''}
                arrayObject.push(rowLT);
                
                var detailData = detail.grid_data;
                for (let i = 0; i < detailData.length; i++){
                    var diskon = 0;
                    var rowOF = {   colA : 'OF',
                                    colB : '1',
                                    colC : detailData[i].item_name,
                                    colD : detailData[i].price_discount_per_unit,
                                    colE : detailData[i].quantity,
                                    colF : Math.round(detailData[i].price_discount),
                                    colG : diskon,
                                    colH : Math.round(detailData[i].price_discount) - diskon,
                                    colI : detailData[i].tax_amount,
                                    colJ : '0',
                                    colK : '0',
                                    colL : '',
                                    colM : '',
                                    colN : '',
                                    colO : '',
                                    colP : '',
                                    colQ : '',
                                    colR : '',
                                    colS : ''}
                    arrayObject.push(rowOF);
                }
            }
        }

        return arrayObject
    }

    invoiceHeaderSizeFaktur(){
        var size = [
            {width: 10}, //colA
            {width: 20}, //colB
            {width: 15}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 25}, //colF
            {width: 25}, //colG
            {width: 20}, //colH
            {width: 30}, //colI
            {width: 30}, //colJ
            {width: 25}, //colK
            {width: 20}, //colL
            {width: 30}, //colM
            {width: 30}, //colN
            {width: 30}, //colO
            {width: 30}, //colP
            {width: 30} //colQ
        ];

        return size;
    }

    invoiceHeaderSizeFakturDetail(){
        var size = [
            {width: 10}, //colA
            {width: 15}, //colB
            {width: 20}, //colC
            {width: 30}, //colD
            {width: 30}, //colE
            {width: 15}, //colF
            {width: 30}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20} //colN
        ];

        return size;
    }

    async invoiceExcelMultiple(data){
        var faktur = [];
        var detailFaktur = [];

        var companyData = await globalfunc.getCompanyInfo();
        var CompanyNPWP = companyData.NPWP.replace(/\./g,'').replace('-','');

        //Faktur Header
        //Row 1
        var row1 = {colA : 'NPWP Penjual',
            colB : '',
            colC : CompanyNPWP};
            faktur.push(row1);

        //Row 2
        var spaceRow = {colA : null};
        faktur.push(spaceRow);

        //Row 3
        var row3 = {colA : 'Baris',
                    colB : 'Tanggal Faktur',
                    colC : 'Jenis Faktur',
                    colD : 'Kode Transaksi',
                    colE : 'Keterangan Tambahan',
                    colF : 'Dokumen Pendukung',
                    colG : 'Referensi',
                    colH : 'Cap Fasilitas',
                    colI : 'ID TKU Penjual',
                    colJ : 'NPWP/NIK Pembeli',
                    colK : 'Jenis ID Pembeli',
                    colL : 'Negera Pembeli',
                    colM : 'Nomor Dokumen Pembeli',
                    colN : 'Nama Pembeli',
                    colO : 'Alamat Pembeli',
                    colP : 'Email Pembeli',
                    colQ : 'ID TKU Pembeli'};
        faktur.push(row3);
        
        //Detail Faktur Header
        //Row 1
        var row1 = {colA : 'Baris',
                    colB : 'Barang/Jasa',
                    colC : 'Kode Barang Jasa',
                    colD : 'Nama Barang/Jasa',
                    colE : 'Nama Satuan Ukur',
                    colF : 'Harga Satuan',
                    colG : 'Jumlah Barang Jasa',
                    colH : 'Total Diskon',
                    colI : 'DPP',
                    colJ : 'DPP Nilai Lain',
                    colK : 'Tarif  PPN',
                    colL : 'PPN',
                    colM : 'Tarif PPnBM',
                    colN : 'PPnBM'};
        detailFaktur.push(row1);

        if(data != null){
            var baris = 1;

            for (let i = 0; i < data.length; i++) {
                var profileCostumerData = data[i].customer.metadata.profile;
                var rowData = { 
                    colA : baris.toString(),
                    colB : moment(data[i].invoice_date).format("DD/MM/YYYY"),
                    colC : 'Normal',
                    colD : profileCostumerData.taxfree ? '07' : '04',
                    colE : '',
                    colF : '',
                    colG : data[i].invoice_number,
                    colH : '',
                    colI : CompanyNPWP + '000000',
                    colJ : profileCostumerData.npwp.replace(/\./g,'').replace('-',''),
                    colK : 'TIN',
                    colL : 'IDN',
                    colM : '-',
                    colN : data[i].customer.contact_name,
                    colO : profileCostumerData.address,
                    colP : profileCostumerData.email,
                    colQ : profileCostumerData.nitku == undefined ? '' : profileCostumerData.nitku}
                faktur.push(rowData);

                var detail = this.itemInvoiceExcel(data[i].transaction_invoice_detail);
                var detailData = detail.grid_data;
                for (let j = 0; j < detailData.length; j++){
                    var diskon = 0;
                    var dpp = detailData[j].price_discount - diskon;
                    var dppOther = (11 / 12) * dpp;

                    var uom = '';
                    switch(detailData[j].base_uom) {
                        case 'KG':
                            uom = 'UM.0003';
                          break;
                        case 'Pcs':
                            uom = 'UM.0021';
                          break;
                        case 'M':
                            uom = 'UM.0013';
                          break;
                        case 'Unit':
                            uom = 'UM.0018';
                        break;
                        case 'Set':
                            uom = 'UM.0019';
                        break;
                        default:
                            uom = 'UM.0021';
                    }
                    
                    var rowDetail = { 
                        colA : baris,
                        colB : 'A',
                        colC : '000000',
                        colD : detailData[j].item_name,
                        colE : uom,
                        colF : detailData[j].price_discount_per_unit,
                        colG : detailData[j].quantity,
                        colH : diskon,
                        colI : dpp,
                        colJ : dppOther,
                        colK : 12,
                        colL : detailData[j].tax_amount,
                        colM : 10,
                        colN : 0,
                        colO : '',
                        colP : '',
                        colQ : '',
                        colR : '',
                        colS : ''}
                    detailFaktur.push(rowDetail);
                }

                baris++;
            }
        }

        //Row END
        var rowEnd = {colA : 'END'};
        faktur.push(rowEnd)
        detailFaktur.push(rowEnd);

        var result = {
            Faktur: faktur,
            DetailFaktur: detailFaktur
        };

        return result;
    }

    itemInvoiceExcel(data){
        var arrayObject = [];
        var totalPriceDiscount = 0;
        var totalTaxAmount = 0;
        var totalVat = 0;
        
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPriceDiscount = totalPriceDiscount + data[i].price_discount;
                totalTaxAmount = totalTaxAmount + data[i].tax_amount * data[i].fulfilled_quantity;
                totalVat = totalVat + data[i].vat;

                var row = { quantity : data[i].fulfilled_quantity * data[i].conv_amount,
                            uom : data[i].uom,
                            base_uom : data[i].base_uom,
                            product_code : data[i].product_code,
                            item_name : data[i].item_name,
                            price_discount_per_unit : data[i].price_discount_per_unit / data[i].conv_amount,
                            price_discount : data[i].price_discount,
                            vat_per_unit : data[i].vat_per_unit,
                            vat : data[i].vat,
                            tax_amount : data[i].tax_amount * data[i].fulfilled_quantity,
                            price_discount_per_unit_string : kendo.toString(data[i].price_discount_per_unit, "n0"),
                            price_discount_string : kendo.toString(data[i].price_discount, "n0") }
                arrayObject.push(row);
            }
        }
        
        return {
            grid_data : arrayObject,
            total_price_discount : totalPriceDiscount,
            total_tax_amount : Math.floor(totalTaxAmount),
            total_vat : totalVat
        };
    }

    itemInvoicePDF(data){
        var arrayObject = [];
        var totalPriceDiscount = 0;
        var totalTaxAmount = 0;
        var totalVat = 0;
        
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPriceDiscount = totalPriceDiscount + data[i].price_discount;
                totalTaxAmount = totalTaxAmount + (data[i].tax_amount * data[i].fulfilled_quantity);
                totalVat = totalVat + data[i].vat;

                var baseQuantity = data[i].fulfilled_quantity * data[i].conv_amount;
           
                var row = { 
                    quantity_string : kendo.toString(data[i].fulfilled_quantity, "n0"),
                    quantity : data[i].fulfilled_quantity,
                    base_quantity_string : kendo.toString(Number(baseQuantity.toFixed(10)), "n0"),
                    base_quantity : Number(baseQuantity.toFixed(10)),
                    uom : data[i].uom,
                    base_uom : data[i].base_uom,
                    product_code : data[i].product_code,
                    item_name : data[i].item_name + " @" + data[i].fulfilled_quantity + " " + data[i].uom,
                    price_discount_per_unit : (data[i].price_discount_per_unit/data[i].conv_amount),
                    price_discount : data[i].price_discount,
                    vat_per_unit : data[i].vat_per_unit,
                    vat : data[i].vat,
                    tax_amount : data[i].tax_amount * data[i].fulfilled_quantity,
                    price_discount_per_unit_string : kendo.toString((data[i].price_discount_per_unit/data[i].conv_amount), "n0"),
                    price_discount_string : kendo.toString(data[i].price_discount, "n0") 
                }
                arrayObject.push(row);
            }
        }

        return {
            grid_data : arrayObject,
            total_price_discount : totalPriceDiscount,
            total_tax_amount : Math.round(totalTaxAmount),
            total_vat : Math.round(totalVat)
        };
    }

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    validateDuplicateSelectedData(data){        
        let arrayObj = [];
        var array = data.split(',');
        for (let i = 0; i < array.length; i++) {
          var detailArray = array[i].split('|');
          var obj = 
            {
              id: detailArray[0],
              purchase_order_id : detailArray[1],
              sp_number : detailArray[2]
            }
          arrayObj.push(obj)
        }
        
        var unique = arrayObj.map(e => e['purchase_order_id'] ).map((e, i, final) => final.indexOf(e) === i && i).filter(obj=> arrayObj[obj]);
        if(unique.length > 1){
            return {
                result : false
            }
        }
        else{
            return {
                result : true,
                deliveryOrderId : arrayObj.map(e => parseInt(e['id'])),
                purchaseOrderId : arrayObj[0].purchase_order_id,
            }
            
        }
    }

    deliveryOrderArrayToString(data){
        let deliveryOrderNumber = [];
        let deliveryOrderId = [];
        for (let i = 0; i < data.length; i++) {
            deliveryOrderNumber.push(data[i].delivery_order_number);
            deliveryOrderId.push(data[i].delivery_order_id);
        }
        
        var result = {  
            delivery_order_id: deliveryOrderId,
            delivery_order_number: deliveryOrderNumber
        };

        return result;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var label = result.data.GetContact[i].contact_name
                var str = {value:result.data.GetContact[i].contact_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getDueDateQuery(id, projectType, typeDetail){
        const variables = {
            id : id,
            project_type : projectType,
            type_detail : typeDetail
        }
        
        var query = gql`query($id:Int!, $project_type:Int!, $type_detail:[String!]!){
            GetTransactionDueDate(customerID:$id, projectType:$project_type, typeDetailIDs:$type_detail)
        }`;
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var duedate = 0;
        if(result.data != null) {
            duedate = result.data.GetTransactionDueDate;
        }
        return duedate;
    }  

    invoiceItemTypeDetailList(data){
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                arrayData.push(data[i].type_detail_id);
            }
        }
        return arrayData;
    }

    async getRecalculateQuery(data){
        const variables = {
            data : data,
        }
        
        var query = gql`query($data:NewTransactionInvoice!){
            CalculateDeposit(new:$data){
                item_id
                base_uom
                uom
                conv_amount
                price_per_unit
                price
                vat_per_unit
                vat
                price_discount_per_unit
                price_discount
                fulfilled_quantity
                discount1
                discount2
                discount3
                discount4
                discount5
                discount_cash
                discount_plus
                discount1_amount
                discount2_amount
                discount3_amount
                discount4_amount
                discount5_amount
                discount_cash_amount
                discount_plus_amount
                tax_amount
                deposit_amount
                item_name
                product_code
            }
        }`;
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        if(result.data != null) {
            return globalfunc.objectToArrayConverter(result.data.CalculateDeposit, "Transaction-Invoice-InvoiceItemDetailEdit");
        }else{
            if(result.errors == undefined || result.errors == null){
                return null;
            }else{
                return "Error";
            }
        }
    }
}

export default new InvoiceService();