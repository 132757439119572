<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import itemServices from '../Script/ItemServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ItemGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: itemServices.readItemQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductMaster.ProductMaster == null){
                            return [];
                        }else{
                            return response.data.GetProductMaster.ProductMaster;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductMaster.ProductMaster == null){
                            return 0;
                        }else{
                            return response.data.GetProductMaster.Total;
                        }
                    },
                    model: {
                        fields: {
                            base_weight: { type: "number"},
                        }
                    }
                }
            }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "active_flag", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: this.statusView }, 
                { field: "product_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "barcode", title: "Barcode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "item_type", title: "Item Type", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "base_uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "base_weight", title: "Berat (Kg)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
                { field: "type_detail_id", title: "Type Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.item_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Item")
        },
        detailInit: function (e) {
            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "itemDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.details);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    // { field: 'item_id', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'uom_conv', title: "Satuan Konversi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'conv_amount', title: "Nilai Konversi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'is_secondary', title: "Is Secondary", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                ],
            })
        },
        dataBound: function (e) {
            // ev.sender.expandRow(e.sender.tbody.find('tr.k-master-row').first())
        },
        statusView(e){
            if(e.active_flag == 'N'){
                return 'New'
            }else if(e.active_flag == 'A'){
                return 'Active'
            }else if(e.active_flag == 'I'){
                return 'Inactive'
            }else{
                return 'Error'
            }
        },
        // changeStatus(status){
        //     this.dataSource = new kendo.data.DataSource({
        //         page: 1,
        //         pageSize: 50,
        //         serverPaging: true,
        //         serverFiltering: true,
        //         serverSorting: true,
        //         transport: {
        //             read: {
        //                 contentType: "application/json",
        //                 url: apiBaseUrl,
        //                 type: "POST",
        //                 data: function(e) {
        //                     const paging = {
        //                         Page : {Limit: e.pageSize,Page: e.page},
        //                         Filter : globalfunc.filterQueryBuilder(e.filter),
        //                         OrderBy : globalfunc.sortingQueryBuilder(e.sort)
        //                     }
        //                     const variables = {
        //                         paging : paging,
        //                         status : status
        //                     }
        //                     return { 
        //                         query: itemServices.readItemQuery(),
        //                         variables : variables
        //                     }
        //                 },
        //                 beforeSend: function (req) {
        //                     req.setRequestHeader('Authorization', window.$cookies.get('Token'));
        //                     req.setRequestHeader('Path', window.location.pathname);
        //                 }
        //             },
        //             parameterMap: function(options, operation) {
        //                 return  kendo.stringify({
        //                     query: options.query,
        //                     variables: options.variables
        //                 });
        //             },
        //             cache: false,
        //         },
        //         schema: {
        //             data: function (response) {
        //                 if(response.data.GetProductMaster.ProductMaster == null){
        //                     return [];
        //                 }else{
        //                     return response.data.GetProductMaster.ProductMaster;
        //                 }
        //             },
        //             total: function (response) {
        //                 if(response.data.GetProductMaster.ProductMaster == null){
        //                     return 0;
        //                 }else{
        //                     return response.data.GetProductMaster.Total;
        //                 }
        //             },
        //         },
        //     });
        // }
    }
}
</script>