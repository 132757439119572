<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import typeDetailServices from '../Script/TypeDetailServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ItemGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
        data: function () {
            return  {
                dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { query: typeDetailServices.readTypeDetailQuery() };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if (response.data.GetTypeDetail == null)
                                return [];
                            else
                                return response.data.GetTypeDetail;
                        },
                        total: function (response) {
                            if (response.data.GetTypeDetail == null)
                                return 0;
                            else 
                                return response.data.GetTypeDetail.length;
                        },
                        model: {
                            fields: {
                                term_of_payment: {type: "number" }
                            }
                        }
                    }
                }),
                columns:  [
                    { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                    { field: "active_flag", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:center;" }  }, 
                    { field: "type_detail_id", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "type_detail_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "group_type_name", title: "Group Type", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                    { field: "material_group_type_name", title: "Item Group", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                    { field: "tax_code", title: "Kode Pajak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                    { field: "commision_status", title: "Status Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:center;" },
                        template: this.statusCommisionView }, 
                    { field: "term_of_payment", title: "Jatuh Tempo (Hari)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right;" } }, 
                    
                ],
                filterableConfig:  {
                    extra: true,
                    operators: kendo_grid.default_operator
                },
                pageableConfig: kendo_grid.default_pageable_config
            };
        },
        mounted: async function() {
            var deleteClick = this.$props.deleteClick;
            var editClick = this.$props.editClick;
            var grid = this.$refs.grid.kendoWidget();
            var GridElement = grid.element;
            
            GridElement.on("click", "#EditButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));

                editClick(dataItem, false);
            })

            GridElement.on("click", "#ViewButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));

                editClick(dataItem, true);
            })

            GridElement.on("click", "#DeleteButton", function (e){
                e.preventDefault();
                var dataItem = grid.dataItem(e.target.closest("tr"));
            
                deleteClick(dataItem.type_detail_id);
            })
        },
        methods: {
            columnButton(){
                return this.$globalfunc.gridActionButton("Type Detail")
            },
            statusCommisionView(e){
                if(e.commision_status == 'A'){
                    return 'Active'
                }else if(e.commision_status == 'I'){
                    return 'Inactive'
                }else{
                    return 'Error'
                }
            }
        }
    }
</script>