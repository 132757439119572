<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import priceServices from '../Script/PriceServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PriceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: 50,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging
                                }
                                return { query: priceServices.readPriceQuery(),
                                         variables: variables};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if (response.data.GetPriceCategory.ItemPriceCategory == null)
                                return [];
                            else
                                return response.data.GetPriceCategory.ItemPriceCategory;
                        },
                        total: function (response) {
                            if (response.data.GetPriceCategory.ItemPriceCategory == null)
                                return 0;
                            else
                                return response.data.GetPriceCategory.Total;
                        },
                    }
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "price_category_id", title: "Kode Kategori Harga", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "price_category_name", title: "Nama Kategori Harga", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "price_type", title: "Tipe Harga", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.price_category_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Pricing")
        },
        statusView(e){
            if(e.active_flag == 'N'){
                return 'New'
            }else if(e.active_flag == 'A'){
                return 'Active'
            }else if(e.active_flag == 'I'){
                return 'Inactive'
            }else{
                return 'Error'
            }
        },
        detailInit: function (e) {
            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "categoryDetailGrid",
                dataSource: {
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: function (x) {
                            x.success(e.data.price_category_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                filterable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'active_flag', title: "Status", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                        template: this.statusView },
                    { field: 'product_code', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'item_name', title: "Nama", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'barcode', title: "Barcode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'type_detail_id', title: "Type Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                    { field: 'uom_conv', title: "Konversi Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'price_retail', title: "Harga Retail",format: "{0:N0}", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'price_project', title: "Harga Project", format: "{0:N0}",width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                ],
            })
        },
        dataBound: function (e) {
            // ev.sender.expandRow(e.sender.tbody.find('tr.k-master-row').first())
        },
    }
}
</script>