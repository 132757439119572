<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nama Gudang</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kode Item</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nama Item</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Barcode</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Tipe Detail</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Satuan Awal</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Satual Akhir</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nilai Konversi</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock Konversi</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock Sales</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock Sales Konversi</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock Minimal</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Stock Minimal Konversi</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2"><label>{{item['storage_name']}}</label></td>
                                <td class="px-2"><label>{{item['product_code']}}</label></td>
                                <td class="px-2"><label>{{item['item_name']}}</label></td>
                                <td class="px-2"><label>{{item['barcode']}}</label></td>
                                <td class="px-2"><label>{{item['type_detail_name']}}</label></td>
                                <td class="px-2"><label>{{item['base_uom']}}</label></td>
                                <td class="px-2"><label>{{item['uom']}}</label></td>
                                <td class="px-2"><label>{{item['conv_amount']}}</label></td>
                                <td class="px-2"><label>{{item['base_stock_gudang']}}</label></td>
                                <td class="px-2"><label>{{item['secondary_stock_gudang']}}</label></td>
                                <td class="px-2"><label>{{item['base_stock_sales']}}</label></td>
                                <td class="px-2"><label>{{item['secondary_stock_sales']}}</label></td>
                                <td class="px-2"><label>{{item['minimal_stock']}}</label></td>
                                <td class="px-2"><label>{{item['secondary_minimal_stock']}}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import forecastingStockService from '../../Areas/Inventory/ForecastingStock/Script/ForecastingStockServices.js';

export default {
    name: 'ForcastingStockPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "ForcastingStock_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(StorageId) {
            var id = StorageId;
            var variables = {
                id
            };

            var data = await forecastingStockService.getForcastingStockQueryByStorageId(variables);
            var gridData = forecastingStockService.ItemPDF(data);
            this.items = gridData;

            this.$refs.html2Pdf.generatePdf();
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>