<template>
    <div class="html-default">
        <label class="form-label" style="font-size: 10px;">{{DatetimeNow}}</label>
        <label class="form-label" style="float: right; font-size: 10px;">{{ProformaInvoiceNumber}}</label>
        <br>
        <div class="row p-2">
            <div class="col-3 bg">
                <img class="item" :src="require('../../../public/img/avatars/Logo.jpg')" width="100%"/>
            </div>
            <div class="col-9 p-0">
                <br>
                <h2><b>PT. BOJONG WESTPLAS</b></h2>
                <label><b>Modern Pipe Manufacturer</b></label>
            </div>
        </div>

        <div style="text-align: center;">
            <h4><b>Proforma Invoice Uang Muka</b></h4>
            <label>{{ProformaInvoiceNumber}}</label>
        </div>

        <div class="row p-2">
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kantor</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyKantor }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Telepon</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPhoneNumber }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Fax</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyFax }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Pabrik</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPabrik }} <br> {{CompanyProvinsi}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Nomor NPWP</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{CompanyNPWP}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Tanggal</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{PODate}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kepada</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                    <label class="form-label"><span>{{customerName}} <br> {{customerAddress}} <br> {{customerCity}} - {{customerProvince}} <br> Indonesia</span></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Alamat Kirim</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label"><span>{{ProjectName}} <br> {{AddressSend}}</span></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2">
            <label class="form-label"><span>Dengan Hormat, <br> Kami menyetujui atas pesanan Saudara yang telah kami terima dengan kondisi sebagai berikut:</span></label>
            <table border="1" class="mytable mytable-head">
                <thead>
                    <tr>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Jumlah</label></td>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Satuan</label></td>
                        <td class="px-3" width="40%" style="text-align: center;"><label>Nama Barang</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Harga</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Total</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in Items" :key="index">
                        <td class="px-2"><label style="float: right;">{{item['quantity_string']}}</label></td>
                        <td class="px-2"><label>{{item['uom']}}</label></td>
                        <td class="px-2"><label>{{item['name']}}</label></td>
                        <td class="px-2"><label style="float: right;">Rp. {{item['net_string']}}</label></td>
                        <td class="px-2"><label style="float: right;">Rp. {{item['total_net_string']}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-body">
                <tbody>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Jumlah harga jual</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalPO}}</label></td>
                    </tr>
                    <tr v-if="this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">Dasar Pengenaan Pajak Nilai Lain</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{OtherTaxBaseAmount}}</label></td>
                    </tr>
                    <tr v-if="this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">PPN = 12% x DPPNL</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalPOPPN}}</label></td>
                    </tr>
                    <tr v-if="!this.FeatureVersion">
                        <td class="px-2" width="80%"><label style="float: right;">PPN = {{PPNTax}}% x Jumlah harga jual</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalPOPPN}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Total</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{NetPO}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Uang Muka {{ DownPaymentPercentage }}%</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">Rp. {{DownPayment}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-footer">
                <tbody>
                    <tr>
                        <td class="px-2" width="100%"><label>#Terbilang: {{POTotalString}} Rupiah</label></td>
                    </tr>
                </tbody>
            </table>
            <br>

            <div class="row">
                <div class="col-3">
                    <label class="form-label">Syarat Pembayaran</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{PaymentTerm}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="form-label">Catatan</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{Note}}</label>
                </div>
            </div>
            <br>
            
            <div class="row">
                <div class="col-8">
                    <label class="form-label"><span>Pembayaran ke PT BOJONG WESTPLAS<br>Dapat ditransfer melalui rekening<br>Bank BCA Cabang CBD Pluit - Jakarta<br>A/N: PT. BOJONG WESTPLAS<br>AC No: 806-040-8999</span></label>
                </div>
                <div class="col-4">
                    <label class="form-label"><span>Tangerang, {{DateNow}}<br>Hormat Kami,<br>PT Bojong Westplas<br><br><br><br><br>{{Name}}</span></label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import piServices from '../../Areas/Transaction/ProformaInvoiceDownPayment/Script/ProformaInvoideDownPaymentScript';
import angkaTerbilang from '@develoka/angka-terbilang-js';

export default {
    name: 'ProformaInvoiceDownPaymentHtml',
    data(){
        return{
            FeatureVersion : '',
            Id: this.$route.params.id,
            DatetimeNow: '',
            DateNow: '',
            ProformaInvoiceNumber: '',
            customerName: '',
            customerAddress: '',
            customerCity: '',
            customerProvince: '',
            PODate: '',
            Items: [],
            Name: this.$cookies.get('Name'),
            TotalPO: '',
            TotalPOPPN: '',
            NetPO: '',
            POTotalString: '',
            PPNTax: '',
            OtherTaxBaseAmount: '',

            DownPaymentPercentage: 0,
            DownPayment: 0,
            PaymentTerm: '',
            Note: '',
            ProjectName: '',
            AddressSend: '',

            //Company Detail
            CompanyPhoneNumber : '',
            CompanyFax : '',
            CompanyKantor : '',
            CompanyNPWP : '',
            CompanyPabrik : '',
            CompanyProvinsi : '',
        }
    },
    async mounted () {
        var featureData = await this.$globalfunc.getFeatureSetting(2);
        var feature = featureData[0].feature_value.version;
        this.FeatureVersion = feature;

        //Company Detail
        var companyData = await this.$globalfunc.getCompanyInfo();
        this.CompanyPhoneNumber = companyData.Telepon;
        this.CompanyFax = companyData.Fax;
        this.CompanyKantor = companyData.Kantor;
        this.CompanyNPWP = companyData.NPWP;
        this.CompanyPabrik = companyData.Pabrik;
        this.CompanyProvinsi = companyData.Provinsi;

        var data = await piServices.getProformaInvoiceQuery(this.Id);
        
        document.title = data.proforma_invoice_number;
        var customerData = await piServices.getContactDetailQuery({type : "Distributor", id : data.customer_id});
        
        this.ProformaInvoiceNumber = data.proforma_invoice_number;
        this.DatetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        this.customerName = customerData.contact_name;
        this.customerAddress = customerData.metadata.profile.address;
        this.customerCity = customerData.metadata.profile.city;
        this.customerProvince = customerData.metadata.profile.province;

        this.PODate = moment(data.purchase_order_date).format("DD MMMM YYYY");
        this.DateNow = moment(new Date()).format("YYYY-MM-DD");

        this.DownPaymentPercentage = data.down_payment_pct;
        this.DownPayment = kendo.toString(data.down_payment, "n0");
        this.PaymentTerm = "DP " + data.down_payment_pct + "%";
        this.Note = data.notes;

        this.ProjectName = data.project_name;
        this.AddressSend = data.address;
        
        //table
        var itemData = piServices.itemPIPDF(data.transaction_proforma_invoice_details);
        this.Items = itemData;

        this.PPNTax = data.tax_percentage;

        var array = this.Items;
        var totalPPNTax = 0;
        var totalGrossDisc = 0;
        var grandTotal = 0;

        if(array.length > 0){
            array.forEach(e => {
                totalGrossDisc = totalGrossDisc + (e.total_net);
                totalPPNTax = totalPPNTax + (e.net * (this.PPNTax / 100)) * e.quantity;
            });
        }

        var otherTaxBaseAmount = totalGrossDisc * (this.PPNTax/12); //DPPNL
        var poPpn = Math.round(otherTaxBaseAmount * (12/100));

        grandTotal = totalGrossDisc + Math.round(totalPPNTax);

        this.TotalPO = kendo.toString(totalGrossDisc, "n0");
        this.OtherTaxBaseAmount = kendo.toString(otherTaxBaseAmount, "n0");
        this.TotalPOPPN = kendo.toString(this.FeatureVersion ? poPpn : totalPPNTax, "n0");
        this.NetPO = kendo.toString(grandTotal, "n0");
        this.POTotalString = this.$globalfunc.titleCase(angkaTerbilang(data.down_payment));
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
.bg {
    background-color: transparent;
}
.item {
    mix-blend-mode: multiply;
}
</style>