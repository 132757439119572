<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="stock"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>

<script>
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import {globalfunc} from '../../../../shared/GlobalFunction';
    import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
    import { kendo_grid } from '../../../../infrastructure/constant/variable';
    
    import ProductionStockServices from '../Script/ProductionStockServices.js';

    export default {
        name: 'ProductionStockGrid',
        components: {
            'kendo-grid' : Grid,
            'kendo-grid-column': GridColumn,
        },
        props: ['StorageId', 'TypeDetail'],
        computed: {
            stock () {
                var storageId = this.StorageId;
                var typeDetail = this.TypeDetail == null ? null : this.TypeDetail.value;
            
                return new kendo.data.DataSource({
                    page: 1,
                    pageSize: 50,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                return { 
                                    query: ProductionStockServices.readStockQuery(),
                                    variables : {
                                        id : storageId, 
                                        paging : paging,
                                        typeDetail : typeDetail
                                    }
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if (response.data.GetItemStockProductionV2.ItemStock == null) {
                                return [];
                            }
                            else {
                                return response.data.GetItemStockProductionV2.ItemStock;
                            }                            
                        },
                        total: function (response) {
                            if (response.data.GetItemStockProductionV2.total == null) {
                                return 0;
                            }
                            else {
                                return response.data.GetItemStockProductionV2.total;
                            }
                        },
                        model: {
                            fields: {
                                weight: { type: "number"},
                                stock: { type: "number"},
                            }
                        }
                    }
                })
            }
        },
        data: function () {
            return  {
                columns: [
                    { field: "storage_name", title: "Nama Storage", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "product_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "barcode", title: "Barcode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'base_uom', title: "Satuan Awal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'weight', title: "Berat Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'stock', title: "Stock", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                ],
                filterableConfig:  {
                    extra: true,
                    operators: kendo_grid.default_operator
                },
                pageableConfig: kendo_grid.default_pageable_config
            };
        },
        mounted: async function() {
        },
        methods: {
            columnButton(){
                return this.$globalfunc.gridActionButton("Stock")
            },
            async excel(storageIdData) {
                var grid = this.$refs.grid.kendoWidget().dataSource;
                
                const paging = {
                    Filter : globalfunc.filterQueryBuilder(grid._filter),
                    OrderBy : globalfunc.sortingQueryBuilder(grid._sort)
                }

                var storageId = this.StorageId;
                var storageName = storageIdData.filter(item => item.value == storageId);

                var typeDetail = this.TypeDetail == null ? null : this.TypeDetail.value;
                var typeDetailName = this.TypeDetail == null ? 'All' : this.TypeDetail.label;
                
                var data = await ProductionStockServices.getDetailProductionStockExcel(paging, storageId, typeDetail);
                var json = ProductionStockServices.ProductionStockExcel(data, storageName[0].label, typeDetailName);
                return json;
            },
        },
    }
</script>
