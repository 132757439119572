<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Formula : {{FormulaCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode Formula</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="FormulaCode" v-model="FormulaCode" class="font-weight-bold"/>
                            <label id="errorFormulaCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nama Formula</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="FormulaName" v-model="FormulaName" class="font-weight-bold"/>
                            <label id="errorFormulaName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tipe Detail</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" @input="onChangeTypeDetail()" :disabled="TypeDetailDisabled"/>
                            <label id="errorTypeDetail" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Item</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col" style="padding-right: 1.8%;" >
                            <v-select id="Item" class="pb-3" :options="ItemData" v-model="Item" :disabled="ItemDisabled"/>
                            <label id="errorItem" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Buffer Quantity (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="BufferQuantity" v-model="BufferQuantity" class="font-weight-bold form-control input-number"/>
                            <label id="errorBufferQuantity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Active</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag" />
                            <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Tipe Detail</h4></label>
                            <div class="row float-right pr-3">
                                <button class="btn btn-primary" @click="addTypeDetailClick()" >
                                    <i class="fa fa-plus"></i> Add Tipe Detail
                                </button>
                            </div>
                            <hr>
                            <datasource ref="typeDetailDataSource" :data="this.TypeDetailGridData" :page-size="10" :schema-model-fields="this.TypeDetailSchemaModel"/>

                            <kendo-grid ref="gridTypeDetail"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :pageable="true"
                                        :resizable="true"
                                        :scrollable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :editable="true"
                                        >

                                <kendo-grid-column  :field="'type_detail_id'"
                                                    :title="'Kode Tipe Detail'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'type_detail_name'"
                                                    :title="'Nama Tipe Detail'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            
                                <kendo-grid-column  :field="'weight'"
                                                    :title="'Berat (Kg)'"
                                                    :width="150"
                                                    :editor="numberEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :title="'&nbsp;'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :command="['destroy']"
                                                    :width="150"></kendo-grid-column>
                                                
                            </kendo-grid>
                        </div>
                    </CRow>
                    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/formula')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <type-detail-data-form ref="typeDetailDataForm" :saveTypeDetailDataGrid="saveTypeDetailDataGrid"/>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import formulaServices from '../Script/FormulaServices.js';
import { response } from '../../../../infrastructure/constant/response';
import TypeDetailDataForm from './TypeDetailDataForm.vue';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'FormulaForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'type-detail-data-form' : TypeDetailDataForm,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            document.getElementById('FormulaCode').readOnly = false;
            this.SaveType = 'Add';

            this.FormulaId = null;
            this.FormulaCode = 'Auto';
            this.FormulaName = '';

            this.TypeDetailData = await formulaServices.getTypeDetailQuery();
            this.TypeDetail = null;
            this.TypeDetailDisabled = false;

            this.ItemData = await formulaServices.getItemQuery(this.TypeDetail);
            this.Item = null;
            this.ItemDisabled = false;

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.ActiveFlagData = [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'}
            ];
            this.ActiveFlag = true;

            this.BufferQuantity = 0;
        }
        else {
            var data = await formulaServices.getFormulaDetail(this.Id);
            
            this.FormulaId = data.formula_id;
            this.FormulaCode = data.formula_code;
            this.FormulaName = data.formula_name;

            this.ItemData = await formulaServices.getItemQuery();
            this.Item = this.ItemData.find(c => c.value == data.item_id);
            this.ItemDisabled = false;

            this.TypeDetailData = await formulaServices.getTypeDetailQuery();
            this.TypeDetail = null;
            this.TypeDetailDisabled = false;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_formula');
            this.Status = data.status;

            this.ActiveFlagData = [
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ];
            this.ActiveFlag = data.is_active;

            this.BufferQuantity = data.buffer_quantity_pct;

            //Grid
            this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(data.formula_detail, "Formula-TypeDetailGridData");

            if(this.View == 'true'){
                $(".save-button").hide();
            }

            document.getElementById('FormulaCode').readOnly = true;
            this.SaveType = 'Edit';
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            //Detail
            FormulaCode: null,
            FormulaName: null,
            FormulaId: null,
            ItemData: [],
            Item: null,
            TypeDetailData: [],
            TypeDetail: null,

            StatusData: [],
            Status: null,

            ActiveFlag: null,
            ActiveFlagData: [],

            BufferQuantity: 0,
          
            SaveType: null,
            ItemDisabled: false,
            TypeDetailDisabled: false,

            //Grid
            TypeDetailGridData: [],
            TypeDetailSchemaModel: {
                formula_detail_id: { type: "string", editable: false },
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                weight: { type: "number", editable: true },
            },
        }
    },
    methods: {
        numberEditor(container, options) { 
            $('<input data-bind="value:' + options.field + '" type="number" />').attr("min", "0").appendTo(container).kendoNumericTextBox({ 
                decimals: 6, // Set the number of decimal places if needed 
                format: "N6", // Set the format if needed 
                restrictDecimals: true 
            })
        },
        async onChangeTypeDetail() {
            var typeDetail = this.TypeDetail == null ? null : this.TypeDetail.value;
            this.ItemData = await formulaServices.getItemQuery(typeDetail);
            this.Item = null;
        },
        addTypeDetailClick(){
            this.$refs.typeDetailDataForm.addClick();
        },
        saveTypeDetailDataGrid(data){
            var gridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var typeDetailData = this.$globalfunc.objectToArrayConverter(gridData, 'Formula-TypeDetailGridData');
            typeDetailData = data.concat(typeDetailData);
            this.TypeDetailGridData = Object.values(typeDetailData.reduce((a, c) => (a[`${c.type_detail_id}${c.type_detail_name}`] = c, a), {})); 
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.FormulaCode == '' || this.FormulaCode == null){
                this.errorShow('errorFormulaCode');
            }
            if(this.FormulaName == '' || this.FormulaName == null){
                this.errorShow('errorFormulaName');
            }
            if(this.Item == '' || this.Item == null){
                this.errorShow('errorItem');
            }
            if (this.BufferQuantity == 0 || this.BufferQuantity == null){
                this.errorShow('errorBufferQuantity');
            }

            if(this.TypeDetailGridData.length < 1){
                this.$swal("Error", "Tipe Detail tidak boleh kosong", "error");
                this.Error++;
            }else{
                var objectArray = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                
                for(let i = 0; i < objectArray.length; i++) { 
                    if(objectArray[i].weight == 0){
                        this.$swal("Error", "Berat pada Tipe Detail tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }
            }
            
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //GridData
                var gridTypeDetail = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                var gridTypeDetailData = formulaServices.convertTypeDetailSave(gridTypeDetail, this.FormulaId);
                
                const formulaData = {
                    formula_id: this.FormulaId,
                    formula_name: this.FormulaName,
                    formula_code: this.FormulaCode,
                    status: this.Status,
                    item_id: this.Item.value,
                    buffer_quantity_pct: parseFloat(this.BufferQuantity),
                    is_active: this.ActiveFlag,
                    detail: gridTypeDetailData
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : formulaData
                    }
                    
                    formulaServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/formula' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : formulaData
                    }
                    
                    formulaServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/formula'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>