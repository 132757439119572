import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class RequestMeterialService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $type:String!, $status:String){
                GetProductionMutation (Paging:$paging, ProductionMutationType:$type,  Status:$status) {
                    production_mutation {
                        created_at
                        last_update
                        production_mutation_id
                        production_mutation_code
                        production_schedule_id
                        production_schedule_code
                        storage_id
                        machine_id
                        machine_code
                        production_mutation_type
                        production_mutation_date
                        status
                        production_id
                        production_code
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getRequestMaterialByIdQuery(type, id){
        const variables = {
            type : type,
            id : [id],
        }
        var query = gql`
            query ($type:String!, $id:[Int]) {
                GetProductionMutation (ProductionMutationType:$type, ProductionMutationIDs:$id) {
                    production_mutation {
                        created_at
                        last_update
                        production_mutation_id
                        production_mutation_code
                        production_schedule_id
                        production_schedule_code
                        storage_id
                        machine_id
                        machine_code
                        production_mutation_type
                        production_mutation_date
                        status
                        production_id
                        production_code
                        notes
                        production_mutation_item {
                            created_at
                            last_update
                            production_mutation_item_id
                            production_mutation_id
                            storage_id
                            item_id
                            product_code
                            item_name
                            base_uom
                            item_type
                            requested_quantity
                            total_approved_quantity
                            secondary_uom
                            secondary_conv_amount
                            secondary_requested_quantity
                            secondary_total_approved_quantity
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProductionMutation.production_mutation[0];
    }

    getQualityControlItem(){
        var query = `
            query ($paging:ServerPaging) {
                GetQualityControlItem (Paging:$paging) {
                    quality_control_item {
                        item_id
                        product_code
                        item_name
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        secondary_uom
                        secondary_conv_amount
                        quantity
                        secondary_quantity
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async calculateQualityControlWeightValidation(variables){
        var query = gql`
            query ($id:Int!, $data:[NewProductionMutationItem!]!) {
                CalculateQualityControlWeight (ProductionMutationID:$id, News:$data)
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.CalculateQualityControlWeight;
    }
    
    detailItemDataObject(data){
        var result = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                production_mutation_id: data[i].production_mutation_id,
                production_mutation_item_id: data[i].production_mutation_item_id,
                storage_id: data[i].storage_id,
                item_id: data[i].item_id,
                item_type: data[i].item_type,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                requested_quantity: data[i].requested_quantity,
                total_approved_quantity: data[i].total_approved_quantity == null ? 0 : data[i].total_approved_quantity,
                secondary_uom: (data[i].secondary_uom == null ? data[i].base_uom : data[i].secondary_uom),
                secondary_conv_amount: (data[i].secondary_conv_amount == null ? 1 : data[i].secondary_conv_amount),
                secondary_requested_quantity: (data[i].requested_quantity / (data[i].secondary_conv_amount == null ? 1 : data[i].secondary_conv_amount)),
                secondary_total_approved_quantity: (data[i].total_approved_quantity / (data[i].secondary_conv_amount == null ? 1 : data[i].secondary_conv_amount)),
            };
            result.push(str);
        }
        
        return result;
    }
    
    stagingItemDataObject(data){
        var dataItem = [{
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_name: data.type_detail_name,
            base_uom: data.base_uom,
            quantity: 0,
            secondary_uom: (data.secondary_uom == null ? data.base_uom : data.secondary_uom),
            secondary_conv_amount: (data.secondary_conv_amount == null ? 1 : data.secondary_conv_amount),
            secondary_quantity: 0,
            mutation_type: null,
        }];
        
        return dataItem;
    }
    
    itemDataObject(data, ProductionMutationId){
        var result = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                production_mutation_id: ProductionMutationId != null ? ProductionMutationId : null,
                production_mutation_item_id: null,
                storage_id: null,
                item_id: data[i].item_id,
                item_type: data[i].mutation_type,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                requested_quantity: data[i].quantity,
                total_approved_quantity: data[i].quantity,
                secondary_uom: data[i].secondary_uom,
                secondary_conv_amount: data[i].secondary_conv_amount,
                secondary_requested_quantity: data[i].secondary_quantity,
                secondary_total_approved_quantity: data[i].secondary_quantity,
            };
            result.push(str);
        }
        
        return result;
    }

    gridDataConverter(data, ProductionMutationId){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                production_mutation_id: ProductionMutationId,
                production_mutation_item_id: data[i].production_mutation_item_id,
                storage_id: data[i].storage_id,
                item_id: data[i].item_id,
                item_type: data[i].item_type,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                requested_quantity: data[i].requested_quantity,
                total_approved_quantity: data[i].total_approved_quantity,
                secondary_uom: data[i].secondary_uom,
                secondary_conv_amount: data[i].secondary_conv_amount,
                secondary_requested_quantity: data[i].secondary_requested_quantity,
                secondary_total_approved_quantity: data[i].secondary_total_approved_quantity,
            }
            array.push(str);
        }
        return array;
    }
    
    duplicateItemChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].item_id == newData.item_id){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    mutationItemDetailQuery(){
        var query = `
            query ($id:Int!){
                GetProductionMutationItemDetail (ProductionMutationItemID:$id) {
                    created_at
                    last_update
                    production_mutation_item_detail_id
                    production_mutation_item_id
                    base_uom
                    quantity
                    secondary_uom
                    secondary_conv_amount
                    secondary_quantity
                    approved_date
                    approved_by
                }
            }
        `;
        return query;
    }

    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewProductionMutation!) {
                UpdateProductionMutation (ProductionMutationID:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}
export default new RequestMeterialService();