<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :editable="true"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import BookingOrderServices from '../Script/BookingOrderServices.js';

export default {
    name: 'BookingOrderGridPerCustomer',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editCustomerClick', 'viewClick', 'deleteClick', 'addClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                { field: "product_code", new_field: "\"Item\".\"product_code\"" },
                                { field: "item_name", new_field: "\"Item\".\"item_name\"" },
                                { field: "customer_name", new_field: "\"PurchaseOrder__Customer\".\"contact_name\"" },
                                { field: "stock_sales", new_field: "\"Item__ItemStockSales\".\"stock\"" },
                                { field: "storage_id", new_field: "\"PurchaseOrder\".\"storage_id\"" },
                            ];
                            const paging = {
                                Page : { Limit: e.pageSize,Page: e.page },
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: BookingOrderServices.readBookingOrderQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetBookingOrder == null) {
                            return [];
                        }
                        else {
                            return response.data.GetBookingOrder.booking_order_v;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetBookingOrder == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetBookingOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            booking_order_id: { type: "number", editable: false } ,
                            purchase_order_number: { editable: false },
                            customer_name: { editable: false },
                            product_code: { editable: false },
                            item_name: { editable: false },
                            uom: { editable: false },
                            stock_sales: { type: "number", editable: false },
                            quantity: { type: "number", editable: false },
                            quantity_ready: { type: "number", editable: true, validation: { min:0 } },
                            quantity_purchase_order: { type: "number", editable: false },
                            price_per_unit: { type: "number", editable: false },
                            vat: { type: "number", editable: false },
                            storage_id: { editable: false },
                        }
                    }
                },
                group: [
                    {field: "customer_name"},
                    {field: "purchase_order_number"},
                ]
            }),
            columns:  [
                { template: this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "booking_order_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "purchase_order_number", title: "No. PO", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "No. PO : #= value #"
                  },
                { field: "customer_name", title: "Customer", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "Customer : #= value #"
                },
                { field: "product_code", title: "Kode Barang", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "stock_sales", title: "Qty Stock Sales", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },attributes: { "class": "k-text-right" } },
                { field: "quantity", title: "Booking", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "quantity_ready", title: "Qty App", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "quantity_purchase_order", title: "To PO", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "price_per_unit", title: "Harga", format: "{0:N2}", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString(price_per_unit, 'n2') #"
                },
                { field: "vat", title: "Total", format: "{0:N2}", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString(vat, 'n2') #"
                },
                { field: "storage_id", title: "Gudang", width: 160, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editCustomerClick = this.$props.editCustomerClick;
        var viewClick = this.$props.viewClick;
        var addClick = this.$props.addClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.booking_order_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editCustomerClick(dataItem);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            viewClick(dataItem.booking_order_id, true);
        })
        
        GridElement.on("click", "#AddButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            addClick(dataItem.booking_order_id, dataItem.purchase_order_id, dataItem.quantity_ready);
        })

        
        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.booking_order_id.toString() + '|' + row.purchase_order_id.toString() + '|' + row.purchase_order_number + '|' + row.quantity_ready;
                       
            var checkedIdArray = hfIDValue.split(';');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + ";" + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + ";" + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButtonBookingOrderOnly("Booking Order")
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        detailInit: async function (e) {
            var data = BookingOrderServices.dataToArray(e.data);

            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "BookingOrderDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'type_detail_id', title: "Tipe", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'created_at', title: "Tanggal", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd MMMM yyyy') #"
                    },
                    { field: 'created_by', title: "User", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "quantity", title: "Booking", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_ready", title: "Qty App", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_purchase_order", title: "Qty To PO", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                ],
            });
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(';');

            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.booking_order_id.toString() + '|' + datarow.purchase_order_id.toString() + '|' + datarow.purchase_order_number + '|' + datarow.quantity_ready;

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }
            else {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" + datarow.uid+  "']").find(".cbFilter")[0];
                    var dataItem = datarow.booking_order_id.toString() + '|' + datarow.purchase_order_id.toString() + '|' + datarow.purchase_order_number + '|' + datarow.quantity_ready;

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>