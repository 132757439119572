<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="stock"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import forecastingStockServices from '../Script/ForecastingStockServices.js';

export default {
    name: 'StockGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['StockId'],
    computed: {
        stock () {
            var stockId = this.StockId;
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: forecastingStockServices.readForcastingStockQuery(),
                                variables : {
                                    id : stockId, 
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetItemStockAdvanced.item_stock == null) {
                            return [];
                        }
                        else {
                            return response.data.GetItemStockAdvanced.item_stock;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetItemStockAdvanced.total == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetItemStockAdvanced.total;
                        }
                    },
                    model: {
                        fields: {
                            conv_amount: { type: "number" },
                            base_stock_gudang: { type: "number"},
                            base_stock_sales: { type: "number"},
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "storage_name", title: "Nama Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "barcode", title: "Barcode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "base_uom", title: "Satuan Awal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "Satuan Akhir", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "conv_amount", title: "Satuan Konversi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "base_stock_gudang", title: "Stock Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "base_stock_sales", title: "Stock Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Stock Forecasting")
        },
    },
    
}
</script>